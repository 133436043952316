import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from '../shared/services/utilities/utilities.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { ChatComponent } from '@app/shared/chat/chat.component';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AngularFirestoreCollection, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { environment } from '@env/environment';
import { MultimediaRoutesService } from '@app/multimedia/services/multimedia-routes.service';
import { Logger } from '@app/core/logger.service';
import { FirebasePaginationService } from '@app/multimedia/services/firebase-pagination.service';
import { HomeService } from './home.service';
import { LoginComponent } from '@app/login/login.component';
import { SearchService } from '@app/shared/services/search/search.service';
import { IonicSlides } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import moment from 'moment';
import { App } from '@capacitor/app';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
const log = new Logger('HomeComponent');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  swiperModules = [IonicSlides];
  subscription: Subscription;
  counter = 0;
  isLoading = false;
  public slides: any[] = [];
  public homeAdvertisings: any[] = [];
  searchStr: string = '';
  onHome: boolean = true;
  public showAdds: boolean = true;
  addCounter: number = 0;
  private bannerColl: AngularFirestoreCollection<any>;

  constructor(
    private homeService: HomeService,
    public router: Router,
    public utilities: UtilitiesService,
    private platform: Platform,
    private toastCtrl: ToastController,
    public credService: CredentialsService,
    private fireService: FirebaseService,
    private analyticsFB: AnalyticsService,
    private aRoute: ActivatedRoute,
    private firePage: FirebasePaginationService,
    private conectimedRoutes: MultimediaRoutesService,
    private modalCtrl: ModalController,
    private searchService: SearchService,
    private alertCtrl: AlertController,
    private authServe: AuthenticationService,
  ) {

  }

  swiperSlideChanged(e: any) {
    log.debug('changed: ', e);
  }

  subscribeParams() {
    this.aRoute.params.subscribe(async params => {
      // Open chat from chat ID
      if (params && params.chatID && params.chatID !== '') {
        try {
          const chatID: string = params.chatID;
          this.router.navigateByUrl('home');
          const data = await this.fireService.afs
            .collection('chats')
            .doc(chatID)
            .ref.get();
          const participants: string[] = data.get('participants') ? Array.from(data.get('participants')) : [];
          const index: number = participants.indexOf(this.credService.credentials.uid);
          if (index > -1) {
            this.goChat(chatID);
          }
        } catch (error) {
          log.error(error);
        }
      }
      // Open chat from user (rep) ID
      if (params && params.userID && params.userID !== '') {
        this.router.navigateByUrl('home');
        const USER_ID: string = atob(decodeURIComponent(String(params.userID)));
        const MESSAGE: string =
          params && params.message && params.message !== ''
            ? atob(decodeURIComponent(String(params.message)))
            : undefined;
        this.fireService.createChat(USER_ID, ChatComponent, undefined, undefined, MESSAGE, true);
      }
      //Open Login
      if (params && params.action && params.action === 'open-login') {
        log.debug('%c=== open-login ===', 'background-color: green; font-size: larger; color: white');
        this.router.navigateByUrl('home').then(() => {
          this.modalLogin();
        });
      }
      if (params && params.dataSurvey && params.dataSurvey !== '') {
        const data = JSON.parse(atob(params.dataSurvey));
        if (data && data.leave_url && data.survey_url) {
          this.router.navigateByUrl(data.leave_url).then(() => {
            this.platform.ready().then(() => {
              if (this.platform.is('android') && this.platform.is('capacitor')) {
                Browser.open({ url: data.survey_url });
              } else {
                this.alertSurvey(data.survey_url);
              }
            });
          });
        }
      }
    });
  }

  private async alertSurvey(url: string) {
    const alert = await this.alertCtrl.create({
      header: 'Encuesta de salida',
      message: 'Lo invitamos a responder la siguiente encuesta',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Responder',
          handler: () => {
            Browser.open({ url: url });
          }
        }
      ]
    });
    alert.present();
  }

  ngOnInit() {
    this.authServe.authState().subscribe(user => {
      if (user && user.uid) {
        this.subscribeParams();
        this.initSlides();
        this.routerAdvertising();
      } else {
        this.initSlides();
      }
    });
  }

  async routerAdvertising() {
    const RESP = await this.fireService.afs
      .collection('advertising')
      .ref.orderBy('order', 'asc')
      .get();
    this.homeAdvertisings = RESP.docs.map(element => {
      const DATA: any = element.data();
      const ID: string = element.id;
      return { id: ID, ...DATA };
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url === '/home' && !this.onHome) {
          if (this.homeAdvertisings.length !== 0) {
            this.showAdds = true;
            this.addCounter = this.addCounter + 1;
            if (this.addCounter >= this.homeAdvertisings.length) {
              this.addCounter = 0;
            }
          }
        }
        if (this.router.url !== '/home') {
          this.onHome = false;
        } else {
          this.onHome = true;
        }
      }
    });
  }

  logScrolling(event: any) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logScrolling(
      event,
      { content_type: 'Home' },
      { credentials: cred, page_data: { route: route_page_url, title: document.title } }
    ); // Analytics LOGS
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe(() => {
      if (this.counter == 0) {
        this.counter++;
        this.presentToast();
        setTimeout(() => {
          this.counter = 0;
        }, 3000);
      } else {
        App.exitApp();
      }
    });
  }

  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: 'Presione nuevamente para salir.',
      duration: 3000
    });
    toast.present();
  }

  ionViewWillLeave() {
    try {
      this.subscription.unsubscribe();
    } catch (e) {
      log.error(e);
    }
  }

  initSlides() {
    this.bannerColl = this.fireService.afs.collection('banners', ref =>
      ref.where('status', '==', 'active').orderBy('order', 'asc')
    );
    this.bannerColl.valueChanges().subscribe(values => {
      let slides: any[] = values;
      slides = slides.filter(slide => {
        const todatMillis = moment()
          .toDate()
          .valueOf();
        const slideMillis = moment(slide['publication_date'].toDate()).valueOf();
        if (todatMillis >= slideMillis) {
          return true;
        } else {
          return false;
        }
      });
      this.slides = slides;
    });
  }

  option(banner: any) {
    this.analyticsClickEvent('click', {
      content_type: banner.title,
      link_url: banner.valueS,
      section_name: 'Home',
      label: banner && banner.analyticsLabel ? banner.analyticsLabel : undefined
    });
    if (banner && banner.typeS === 'search') {
      this.router.navigate([`/multimedia/other/${String(banner.valueS)}`]);
    } else if (banner && banner.typeS === 'modal') {
      this.openPost(banner.valueS);
    } else {
      let url: string = banner.valueS;
      if (url.includes(environment.APP_URL)) {
        url = url.split(environment.APP_URL)[1];
        this.router.navigateByUrl(url);
      } else {
        this.presentPage(banner.valueS);
      }
    }
  }

  presentPage(url: string) {
    Browser.open({ url: url });
  }

  async openPost(id: number) {
    try {
      const response = await this.firePage.getDocData(`posts/${id}`);
      let item: any = { id: id, ...response.data() };
      item.routing = this.conectimedRoutes.mapRoute(item.category, item.id, item.title);
      this.router.navigate([item.routing.route]);
    } catch (error) {
      log.error(error);
    }
  }

  showToast() {
    this.utilities.toast('Estamos mejorando esta sección, espérala nuevamente muy pronto');
  }

  public share(item: any) {
    this.utilities.share(item.routing.route);
    this.analyticsClickEvent('share', {
      content_type: item && item.title ? item.title : ''
    });
  }

  async goChat(id: string, accepted: boolean = true): Promise<void> {
    this.analyticsClickEvent('click', {
      content_type: 'chat'
    });
    try {
      this.fireService.goChat(id, ChatComponent, accepted);
    } catch (error) {
      log.error(error);
    }
  }

  public keypressEvent(event: any): void {
    if (event && event.key === 'Enter') {
      this._search();
    }
  }

  public _search() {
    const search: string = this.searchService.clearSearch(this.searchStr, true);
    if (search && search.length >= 3) {
      this.router.navigateByUrl(`/multimedia/posts/all/${search}`);
    }
  }

  public loginAlert() {
    this.homeService.alertLoginSining();
  }

  public async modalLogin() {
    const modal = await this.modalCtrl.create({
      component: LoginComponent
    });
    modal.present();
  }
}
