import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Logger } from '@app/core/logger.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { UpdatePhoneComponent } from '../update-phone/update-phone.component';
import { AlertController, ModalController } from '@ionic/angular';
import { EmailService } from '@app/shared/services/email/email.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Storage } from '@ionic/storage-angular';
import { timer } from 'rxjs';
import moment from 'moment';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { AppComponent } from '@app/app.component';
import { environment } from '@env/environment';
import FIRE from "firebase/compat/app";
import "firebase/auth"
const log = new Logger('WaitingRoomComponent');

@Component({
  selector: 'app-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.scss']
})
export class WaitingRoomComponent implements OnInit {
  public _validatePerEmail: boolean = false;
  public displayPhone: string;
  public code: string;
  public isLoading: boolean = false;
  public counter = 0;
  public email: string;
  public completed: boolean = false;
  private mobileVerified: boolean;
  private emailVerified: boolean;
  private phone: string;
  private uid: string;
  private countDown: Subscription;
  private count = 300;
  private tick = 1000;

  constructor(
    private router: Router,
    private fireService: FirebaseService,
    private loader: IonLoaderService,
    private alertCtrl: AlertController,
    private utilities: UtilitiesService,
    private authServe: AuthenticationService,
    private analyticsFB: AnalyticsService,
    private modalCtrl: ModalController,
    private emailService: EmailService,
    private storage: Storage,
    private credService: CredentialsService,
    private app: AppComponent
  ) {
    this.initializeApp();
  }

  private async checkTimer() {
    try {
      const currentDate = moment();
      const counter = await this.storage.get('mobile_counter_date');
      this._validatePerEmail = await this.storage.get('validate_per_email') || false;

      if (counter) {
        let duration = moment.duration(currentDate.diff(moment(counter)));
        let seconds = duration.asSeconds();
        if (seconds < this.count) {
          this.startCounter1(this.count - seconds);
        } else {
          this.storage.set('mobile_counter_date', undefined);
        }
      }
    } catch (error) {
      log.error(error);
    }
  }

  private analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials;
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  private async initializeApp() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      await this.initData();
    } catch (error) {
      log.error('********************* Error *********************', error);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  ngOnInit() {
    this.checkTimer();
  }

  private async initData() {
    try {
      let authState: firebase.default.User = await this.authServe.getUser();
      if (authState && authState.uid && authState.isAnonymous === false) {
        const complete: boolean = await this.authServe.registrationComplete(authState.uid);
        log.debug(`%cComplete: ${complete}`, 'background-color: blue; font-size: larger; color: white');
        const userData = await this.fireService.afs
          .collection('users')
          .doc(authState.uid)
          .ref.get();
        if (userData.get('type') === 'representante-medico') {
          this.router.navigate(['/register/representante-medico/complete-registration']).then(() => {
            this.reload();
          });
        }
        if (!complete === true) {
          if (userData.get('type') === 'medico' || userData.get('type') === 'profesional-de-la-salud') {
            this.router.navigate(['/register/complete-registration']).then(() => {
              this.reload();
            });
          }
        }
        const phoneData = await this.fireService.afs
          .collection('activation-codes')
          .doc(authState.uid)
          .ref.get();
        this.uid = authState.uid;
        this.emailVerified = authState.emailVerified;
        this.email = authState.email;
        this.phone = userData.get('mobile');
        const respLada = userData.get('mobileLada');
        this.displayPhone = this.utilities.getPhone(this.phone, respLada && respLada.lada ? respLada.lada : undefined);
        this.mobileVerified =
          phoneData && phoneData.get('status') && phoneData.get('status') === 'active' ? true : false;
        this.emailVerified = Boolean(this.mobileVerified);
        if (this.mobileVerified === true || this.emailVerified === true) {
          this.completed = true;
          this.finalize();
        }
      }
      else {
        this.router.navigate(['/home'], { replaceUrl: true });
      }
    } catch (error) {
      log.error('********************* Error *********************', error);
    }
  }

  private async sendMobileVerification() {
    this.startCounter1();
    this._sendMobileVerification();
  }

  private async _sendMobileVerification() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      await this.fireService.sendMobileVerification({ number: this.phone, uid: this.uid });
      this.utilities.toast('Código enviando correctamente.', 'Reenvío de código telefónico');
    } catch (error) {
      log.error('====== Error ======', error);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  private async startCounter1(counter?: number) {
    this.counter = counter ? counter : this.count;
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter <= 0) {
        this.countDown.unsubscribe();
        this.storage.set('mobile_counter_date', undefined);
        return 0;
      }
      return --this.counter;
    });
    if (!!!counter) {
      const date: number = moment().valueOf();
      await this.storage.set('mobile_counter_date', date);
    }
  }

  private async changePhone() {
    const modal = await this.modalCtrl.create({
      component: UpdatePhoneComponent
    });
    modal.onDidDismiss().then(data => {
      if (data && data.role && data.role === 'updated') {
        this.changePhoneHandler();
      }
    });
    modal.present();
  }

  private async changePhoneHandler() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      this.storage.set('validate_per_email', false);
      this._validatePerEmail = false;
      await this.initializeApp();
      await this.sendMobileVerification();
    } catch (error) { }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  private async alertError(msg: string) {
    const alert = await this.alertCtrl.create({
      header: 'Ha ocurrido un error',
      message: msg,
      buttons: [
        {
          text: 'Ok'
        }
      ]
    });
    alert.present();
  }

  private async finalize() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      await this.fireService.activateUser(this.uid, false);
      await this.emailService.registerAndWelcome(this.uid);
      this.analyticsClickEvent('click', {
        content_type: 'Finalizado',
        section_name: 'Registro de usuario',
        click_text: 'Finalizar',
        user_type: 'medico'
      });
      this.router.navigate(['/home'], { replaceUrl: true }).then(() => {
        this.reload();
      });
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  private async reload() {
    const currentUser = await this.authServe.currentUser();
    await this.app.setUserDataAndCredentials(currentUser);
  }

  private async sendEmailVerification() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const code: string = await this.fireService.code({ type: 'email', uid: this.uid, email: this.email });
      await this.emailService.email({
        recipient: this.email,
        subject: 'Conectimed: Verificación de cuenta por email',
        text: `<div style="text-align:left !important;">
        <p>Estimado/a usuario/a,</p> 

        <p>Gracias por registrarse en Conectimed. Para completar el proceso de verificación de su cuenta, por favor utilice el siguiente código: <strong>${code}</strong></p> 

        <p>Para activar su cuenta, siga estos pasos:</p>
        <ol>
          <li>Regrese a la aplicación de <strong>Conectimed</strong> ó haga <a href="${environment.APP_URL}/register/account-activation"><strong>clic aquí</strong></a>.</li>
          <li>En la pantalla de verificación, ingrese el código de verificación en el apartado de "Activar mi cuenta".</li>
          <li>Presione el botón "Continuar" para completar el proceso.</li>
        </ol>

        <p>Si no solicitó este código, por favor ignore este mensaje. Si tiene algún problema o necesita ayuda, no dude en contactarnos a través de nuestro soporte técnico.</p>
        </div>

        <p>Atentamente,</p>
        <p>Equipo Conectimed</p>`
      });
      await this.storage.set('first_time_email_send', true);
      this.utilities.toast('Código enviando correctamente.', 'Reenvío de código por email');
    } catch (error) {
      log.error('====== Error ======', error);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  private async changeEmail() {
    const alert = await this.alertCtrl.create({
      header: 'Actualizar mi email',
      message: 'A continuación ingrese su contraseña y el nuevo email:',
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Contraseña',
          value: ''
        },
        {
          name: 'email',
          type: 'email',
          placeholder: 'contacto@conectimed.com',
          value: ''
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          cssClass: 'secondary'
        },
        {
          text: 'Actualizar',
          handler: async data => {
            if (data && data.email && data.password) {
              this.changeEmailHandler(data);
            } else {
              this.alertError('Los campos anteriores deben contener información.');
            }
          }
        }
      ]
    });
    await alert.present();
  }

  private async changeEmailHandler(data: any) {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const new_email: string = String(data.email)
        .toLocaleLowerCase()
        .trim();
      const email: string = await (await this.authServe.getUser()).email;
      const password: string = data.password;
      const credential = FIRE.auth.EmailAuthProvider.credential(email, password);
      var user = FIRE.auth().currentUser;
      const resp = await user.reauthenticateWithCredential(credential);
      if (resp && resp.user && resp.user.uid) {
        await resp.user.updateEmail(new_email);
        await this.fireService.afs
          .collection('users')
          .doc(resp.user.uid)
          .update({ email: new_email });
        await this.initData();
        this._validatePerEmail = true;
        this.storage.set('validate_per_email', true);
        await this.sendEmailVerification();
        this.startCounter1();
        this.utilities.toast('Datos actualizados correctamente.', 'Correcto');
      }
    } catch (error: any) {
      if (error && error.code) {
        switch (error.code) {
          case 'auth/wrong-password':
            this.alertError('La contraseña no es válida o el usuario no tiene contraseña.');
            break;
          case 'auth/email-already-in-use':
            this.alertError('El correo que ingreso ya se encuentra asociado a otra cuenta.');
            break;
          case 'auth/invalid-email':
            this.alertError('Ingresa una dirección de correo electrónico válida.');
            break;
          case 'auth/too-many-requests':
            this.alertError(
              'Hemos bloqueado todas las solicitudes de este dispositivo debido a una actividad inusual. Vuelva a intentarlo más tarde.'
            );
            break;
          default:
            this.alertError('Ha ocurrido un error, por favor intentalo mas tarde.');
            break;
        }
      }
      log.error(error.code);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  public onCodeChanged(value: string) {
    this.code = value;
  }

  public async optionsAlert() {
    const _alert = await this.alertCtrl.create({
      header: '¿No recibió el código?',
      message: 'A continuación, elija el método al cual reenviaremos el código de verificación.',
      inputs: [
        {
          label: `Enviar a ${this.displayPhone}`,
          type: 'radio',
          checked: true,
          value: 'phone',
        },
        {
          label: `Enviar a ${this.email}`,
          type: 'radio',
          value: 'email',
        },
        {
          label: 'Cambiar de email',
          type: 'radio',
          value: 'change-email',
        },
        {
          label: 'Cambiar de teléfono',
          type: 'radio',
          value: 'change-phone',
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          cssClass: 'secondary'
        },
        {
          text: 'Seleccionar',
          handler: (type) => {
            switch (type) {
              case 'email':
                this._validatePerEmail = true;
                this.storage.set('validate_per_email', true);
                this.sendEmailVerification();
                this.startCounter1();
                return true;
              case 'phone':
                this.storage.set('validate_per_email', false);
                this._validatePerEmail = false;
                this.sendMobileVerification();
                this.startCounter1();
                return true;
              case 'change-email':
                this.changeEmail();
                return true;
              case 'change-phone':
                this.changePhone();
                return true;
              default:
                return false;
            }

          }
        }
      ]
    });
    await _alert.present();
  }

  public async codeVerification() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const resp = await this.fireService.afs
        .collection('activation-codes')
        .doc<any>(this.uid)
        .ref.get();
      const data = resp.data();
      if (
        data &&
        data.code &&
        String(data.code) === String(this.code)
      ) {
        await await this.fireService.afs
          .collection('activation-codes')
          .doc(this.uid)
          .update({ status: 'active' });
        await this.initData();
        this.utilities.toast('Confirmación de cuenta realizada con éxito.', 'Correcto');
      } else {
        this.alertError(
          'El código ingresado no corresponde con el registrado, por favor revise la información o solicite un nuevo código de verficación.'
        );
      }
    } catch (error) {
      log.error('********************* Error *********************', error);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  public deleteV() {
    this.storage.set('mobile_counter_date', undefined);
    window.location.reload();
  }
}
