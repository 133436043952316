<div *ngIf="featured.length === 0">
  <ion-card class="featured-post ion-padding-horizontal">
    <ion-card-title>
      <h2 class="custom-title">
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
      </h2>
    </ion-card-title>
    <ion-card-content>
      <div class="custom-image">
        <ion-skeleton-text animated style="width: 100%; height: 300px;"></ion-skeleton-text>
      </div>
      <ion-toolbar>
        <ion-buttons slot="end" class="ion-no-margin">
          <ion-button fill="outline" color="medium">
            <ion-skeleton-text animated style="width: 85px"></ion-skeleton-text>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-card-content>
  </ion-card>
  <ion-card class="featured-post ion-padding-horizontal">
    <ion-card-title>
      <h2 class="custom-title">
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
      </h2>
    </ion-card-title>
    <ion-card-content>
      <div class="custom-image">
        <ion-skeleton-text animated style="width: 100%; height: 300px;"></ion-skeleton-text>
      </div>
      <ion-toolbar>
        <ion-buttons slot="end" class="ion-no-margin">
          <ion-button fill="outline" color="medium">
            <ion-skeleton-text animated style="width: 85px"></ion-skeleton-text>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-card-content>
  </ion-card>
  <ion-card class="featured-post ion-padding-horizontal">
    <ion-card-title>
      <h2 class="custom-title">
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
      </h2>
    </ion-card-title>
    <ion-card-content>
      <div class="custom-image">
        <ion-skeleton-text animated style="width: 100%; height: 300px;"></ion-skeleton-text>
      </div>
      <ion-toolbar>
        <ion-buttons slot="end" class="ion-no-margin">
          <ion-button fill="outline" color="medium">
            <ion-skeleton-text animated style="width: 85px"></ion-skeleton-text>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-card-content>
  </ion-card>
</div>
<div *ngFor="let item of featured; let i = index">
  <ion-card class="featured-post ion-padding-horizontal" [button]="true"
    *ngIf="credService?.credentials?.uid && credService?.credentials?.active === true;  else anonymousFeaturedCard">
    <ion-card-title [routerDirection]="'forward'" [routerLink]="[item?.routing?.route]">
      <h2 class="custom-title">
        <ion-icon class="custom-icon" *ngIf="item?.routing?.icon?.src as src" [src]="src" color="secondary"></ion-icon>
        <ion-icon class="custom-icon" *ngIf="item?.routing?.icon?.name as name" [name]="name" color="secondary"></ion-icon>
        {{
        item?.title }}</h2>
    </ion-card-title>
    <ion-card-content>
      <div class="custom-image" [routerDirection]="'forward'" [routerLink]="[item?.routing?.route]">
        <img [src]="item?.image" [alt]="item?.title" />
      </div>
      <ion-toolbar>
        <app-user-social-media-options slot="end" [item]="item" [favoriteCheck]="true" [view]="1"></app-user-social-media-options>
      </ion-toolbar>
    </ion-card-content>
  </ion-card>
  <ng-template #anonymousFeaturedCard>
    <ion-card class="featured-post ion-padding-horizontal" [button]="true" (click)="loginAlert()">
      <ion-card-title>
        <h2 class="custom-title"><ion-icon class="custom-icon" [src]="item?.routing?.icon"
            color="secondary"></ion-icon> {{
          item?.title }}</h2>
      </ion-card-title>
      <ion-card-content>
        <div class="custom-image">
          <img [src]="item?.image" [alt]="item?.title" />
        </div>
        <ion-toolbar>
          <ion-buttons slot="end" class="ion-no-margin">
            <ion-button fill="outline" color="medium">
              <ion-icon slot="start" name="share-social-outline"></ion-icon>
              Compartir
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-card-content>
    </ion-card>
  </ng-template>
</div>