<ion-buttons slot="end" *ngIf="view === 1">
  <ion-button size="large" (click)="like()" fill="clear" [color]="item?.like === true ? 'primary': 'medium'"
    [disabled]="!(credService?.credentials?.uid && credService?.credentials?.active === true) || isLoading == true">
    {{ item?.likeCount && item?.likeCount > 0 ? item?.likeCount : 0 }}
    <ion-icon size="large" slot="end" [name]="item?.like === true ? 'heart': 'heart-outline'"></ion-icon>
  </ion-button>
  <ion-button size="large" (click)="favorite()" fill="clear" [color]="item?.favorite === true ? 'warning': 'medium'"
    [disabled]="!(credService?.credentials?.uid && credService?.credentials?.active === true) || isLoading == true">
    <ion-icon size="large" slot="icon-only" [name]="item?.favorite === true ? 'star': 'star-outline'"></ion-icon>
  </ion-button>
  <ion-button size="large" *ngIf="favoriteCheck === true" (click)="share()" fill="clear" color="medium"
    [disabled]="!(credService?.credentials?.uid && credService?.credentials?.active === true)">
    <ion-icon size="large" slot="icon-only" name="share-social-outline"></ion-icon>
  </ion-button>
</ion-buttons>

<ion-buttons slot="end" *ngIf="view === 2">
  <ion-button size="large" (click)="like()" fill="clear" [color]="item?.like === true ? 'primary': 'medium'"
    [disabled]="!(credService?.credentials?.uid && credService?.credentials?.active === true) || isLoading == true">
    {{ item?.likeCount && item?.likeCount > 0 ? item?.likeCount : 0 }}
    <ion-icon size="large" slot="end" [name]="item?.like === true ? 'heart': 'heart-outline'"></ion-icon>
  </ion-button>
  <ion-button size="large" *ngIf="favoriteCheck === true" (click)="favorite()" (click)="favorite()" fill="clear" [color]="item?.favorite === true ? 'warning': 'medium'"
    [disabled]="!(credService?.credentials?.uid && credService?.credentials?.active === true) || isLoading == true">
    <ion-icon size="large" slot="icon-only" [name]="item?.favorite === true ? 'star': 'star-outline'"></ion-icon>
  </ion-button>
  <ion-button size="large" (click)="share()" fill="clear" color="medium"
    [disabled]="!(credService?.credentials?.uid && credService?.credentials?.active === true)">
    <ion-icon size="large" slot="icon-only" name="share-social-outline"></ion-icon>
  </ion-button>
</ion-buttons>

<!-- <ion-grid [fixed]="false" *ngIf="view === 2">
  <ion-row class="ion-justify-content-center">
    <ion-col size="auto" class="ion-text-center">
      <ion-button size="large" (click)="like()" fill="clear" [color]="item?.like === true ? 'primary': 'medium'"
        [disabled]="!(credService?.credentials?.uid && credService?.credentials?.active === true) || isLoading == true">
        {{ item?.likeCount && item?.likeCount > 0 ? item?.likeCount : 0 }}
        <ion-icon size="large" slot="end" [name]="item?.like === true ? 'heart': 'heart-outline'"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col size="auto" class="ion-text-center">
      <ion-button size="large" fill="clear" color="medium" (click)="share()">
        <ion-icon size="large" slot="icon-only" name="share-social-outline"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col size="auto" class="ion-text-center">
      <ion-button size="large" *ngIf="favoriteCheck === true" (click)="favorite()" fill="clear"
        [color]="item?.favorite === true ? 'warning': 'medium'"
        [disabled]="!(credService?.credentials?.uid && credService?.credentials?.active === true) || isLoading == true">
        <ion-icon size="large" slot="icon-only" [name]="item?.favorite === true ? 'star': 'star-outline'"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid> -->