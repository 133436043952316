
import { env } from './.env';

export const environment = {
  production: false,
  hmr: false,
  version: env.npm_package_version + '-dev',
  serverUrl: '/api',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  sendEmails: true,
  WP_JSON_URL: 'https://panel.conectimed.com/',
  MOODLE_JSON_URL:
    'https://moodle.conectimed.site/webservice/rest/server.php?wstoken=aa683b4e180073d92d864dae7000e134&moodlewsrestformat=json',
  MOODLE_JSON_URL_SINGLE:
    'https://moodle.conectimed.site/webservice/rest/server.php?moodlewsrestformat=json&wstoken=',
  MOODLE_JSON_URL_TWO:
    'https://moodle.conectimed.site/webservice/rest/server.php?wstoken=bae780b8a74b56a3e992c21b05cc6597&moodlewsrestformat=json',
  MOODLE_URL: 'https://moodle.conectimed.site/login/token.php?service=moodle_mobile_app',
  MOODLE_IFRAME_URL: 'https://moodle.conectimed.site/mod/feedback/view.php?id=',
  MOODLE_USER_WEBSERVICE_ID: 3,
  FIREBASE_API: 'https://us-central1-conectimed-9d22c.cloudfunctions.net/',
  OPEN_PAY_ID: 'mnk1grtuhq4tioa0za8y',
  OPEN_PAY_API_KEY: 'pk_903a7285320a4c97a93c6fd8d2f16e9a',
  OPEN_PAY_SANDBOX: true,
  CONTENT_EMAIL: 'contenidos@conectimed.com',
  SALES_EMAIL: 'ventas@conectimed.com',
  CONTACT_EMAIL: 'contactodev@conectimed.com',
  COMERCIAL_EMAIL: 'comercial@conectimed.com',
  CLICK_BANNERS_EMAIL: 'clickbanners@yopmail.com',
  APP_URL: 'https://app.conectimed.site',
  DEEPLINK_URL: 'app.conectimed.site',
  MAIN_PHONE: '5525893520',
  WHATSAPP_PHONE: '5534303549',
  URL_SALUD_360: 'https://salud-360.com.mx/categorias/conectymed?sort_by=name-ascending',
  WEB_VERSION: '6',
  DEFAULT_USERS_CONNECTIONS: [
    {
      email: 'contacto@conectimed.com',
      name: 'Pablo Gorozpe',
      id: 'qBN9po2OxPZTO9ltHPzlAqRrnec2'
    },
    {
      email: 'apando@conectimed.com',
      name: 'Arturo Pando',
      id: 'VyaFDiU3p8bxzGzB3k5NWcfjLah2'
    },
    {
      email: 'brittany.payne@yopmail.com',
      name: 'Rolando Alberto',
      id: 'ZPhlHQI5JzQzCMxNTw4LQaFwxee2'
    }
  ],
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyDNse9Pg_XQHuA5Rq9Kl7mK2xj7EmS1-Ps',
    authDomain: 'conectimed-9d22c.firebaseapp.com',
    databaseURL: 'https://conectimed-9d22c.firebaseio.com',
    projectId: 'conectimed-9d22c',
    storageBucket: 'conectimed-9d22c.appspot.com',
    messagingSenderId: '373046668834',
    appId: '1:373046668834:web:2e90f93e4132ab902f247b',
    measurementId: 'G-4HZE3BRY9V'
  },
  CUSTOM_SECURITY: {
    private_key: 'pWvfprKozZbLNkMyaMCSQZRmD0GPVsbY',
    public_key: 'MaDavYr5WY3YQUgL'
  },
  IOS_BUNDLE_ID: 'com.conectimed.app',
  APNS_URL: 'https://iid.googleapis.com/iid/v1:batchImport',
  REPORTS_EMAIL: 'conctacto@conectimed.com',
  URL_MEET: 'https://test-conectimed.web.app/video-conference/',
  VIDEO_IDS: {
    webinars: { id: 6748, ref: 'categories_video/6748' },
    entrevista: { id: 6749, ref: 'categories_video/6749' },
    notimed: { id: 50, ref: 'categories_video/50' }
  },
  TYPE_3_USER: 'appmanager',
  APP_DOMAINS: [
    'http://localhost:4200',
    'http://localhost:5000',
    'http://localhost:5005',
    'https://app.conectimed.site',
    'https://app.conectimed.com',
    'https://conectimed-fb-dev--test-39kix1rw.web.app'
  ],
  FAQ: {
    representante: 'preguntas-frecuentes-representante',
    medico_web: 'preguntas-frecuentes-medicos-web',
    medico_movil: 'preguntas-frecuentes-medicos-movil'
  },
  ZOOM_SDK_KEY: 'qHY9PkWGfgkOXxZUIMmO6gBZJLjhbBcLoWvZ'
};